import React, { useState, useEffect } from "react";
import "../styles/ProjectList.css";
import Linkify from "react-linkify";
import io from "socket.io-client";
import axios from "axios";
const socket = io("https://api-spotauto.onrender.com"); // Your server's URL

const ProjectList = ({
  projects,
  userType,
  onUploadImage,
  selectedFiles,
  setSelectedFiles,
  onUpdateDescription,
  onUpdateAssign,
  onProjectDeletion,
  onProjectDone,
  updateProjects,
  setProjects,
  
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [editingDescriptionId, setEditingDescriptionId] = useState(null);
  const [updatedDescription, setUpdatedDescription] = useState("");
  const [editingAssignId, setEditingAssignId] = useState(null);
  const [updatedAssign, setUpdatedAssign] = useState("");
  const [editingCommentCounter, setEditingCommentCounter] = useState(1);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("startDate");
  

  useEffect(() => {
    const handleDescriptionUpdated = ({ projectId, updatedDescription }) => {
      setProjects((currentProjects) =>
        currentProjects.map((project) => {
          if (project.id === projectId) {
            return { ...project, description: updatedDescription };
          }
          return project;
        })
      );
    };

    const handleAssignUpdated = ({ projectId, updatedAssign }) => {
      console.log(`Assign update received for project ID: ${projectId}: ${updatedAssign}`);
      setProjects((currentProjects) =>
        currentProjects.map((project) => {
          if (project.id === projectId) {
            return { ...project, assign: updatedAssign };
          }
          return project;
        })
      );
    };

    // Check if socket is connected before adding the listener
    if (socket && socket.connected) {
      socket.on("descriptionUpdated", handleDescriptionUpdated);
      socket.on("assignUpdated", handleAssignUpdated);
    }

    return () => {
      // Clean up the listener when the component unmounts
      if (socket && socket.connected) {
        socket.off("descriptionUpdated", handleDescriptionUpdated);
        socket.off("assignUpdated", handleAssignUpdated);
      }
    };
  }, [socket, setProjects]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleProjectDone = (projectId) => {
    // Call the onProjectDone handler passed from the AdminDashboard
    onProjectDone(projectId);
  };

  const handleFileChange = (event, projectId) => {
    const files = Array.from(event.target.files);
    setSelectedFiles((prevSelectedFiles) => ({
      ...prevSelectedFiles,
      [projectId]: files,
    }));
  };

  const handleRemoveImage = (projectId, file) => {
    setSelectedFiles((prevSelectedFiles) => {
      const updatedFiles = { ...prevSelectedFiles };
      const projectFiles = updatedFiles[projectId];
      const fileIndex = projectFiles.findIndex((f) => f === file);
      if (fileIndex !== -1) {
        projectFiles.splice(fileIndex, 1);
      }
      return updatedFiles;
    });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, projectId) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    setSelectedFiles((prevSelectedFiles) => ({
      ...prevSelectedFiles,
      [projectId]: files,
    }));
  };

  const handleDescriptionEdit = (projectId, description) => {
    setEditingDescriptionId(projectId);

    // Add "___________\ncmt: -" line below the existing description
    const editedDescription =
      description +
      "\n- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -\nCmt : -";
    setUpdatedDescription(editedDescription);
  };

  const handleDescriptionChange = (event) => {
    setUpdatedDescription(event.target.value);
  };

  const handleDescriptionUpdate = (projectId) => {
    onUpdateDescription(projectId, updatedDescription);
    setEditingDescriptionId(null);
    setUpdatedDescription('');
    // Make the HTTP POST request to update the description
    
    axios.post('https://api-spotauto.onrender.com/update-description', {
      projectId: projectId,
      updatedDescription: updatedDescription,
    })
      
  };

  const handleAssignEdit = (projectId, assign) => {
    setEditingAssignId(projectId);
    setUpdatedAssign(assign);
  };

  const handleAssignChange = (event) => {
    setUpdatedAssign(event.target.value);
  };

  const handleAssignUpdate = (projectId) => {
    onUpdateAssign(projectId, updatedAssign);
    setEditingAssignId(null);
    setUpdatedAssign("");

    axios.post('https://api-spotauto.onrender.com/update-assign', {
      projectId: projectId,
      updatedAssign: updatedAssign,
    })
  };

  const handleProjectDelete = (projectId) => {
    onProjectDeletion(projectId);
  };
  const customComponentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );
  const handleSort = (column) => {
    setSortOrder((prevSortOrder) =>
      column === sortColumn ? (prevSortOrder === "asc" ? "desc" : "asc") : "asc"
    );
    setSortColumn(column);
  };

  const sortedProjects = [...projects].sort((a, b) => {
    const sortOrderFactor = sortOrder === "asc" ? 1 : -1;
    const columnA = a[sortColumn];
    const columnB = b[sortColumn];

    if (columnA < columnB) {
      return -1 * sortOrderFactor;
    }
    if (columnA > columnB) {
      return 1 * sortOrderFactor;
    }
    return 0;
  });

  return (
    <div>
      <table className="project-table">
        <thead>
          <tr>
            <th>Project Name</th>
            {userType === "admin" && <th>Client Email</th>}
            {userType === "admin" && <th>Assign</th>}
            {userType === "artist" && <th></th>}
            <th>Description</th>
            <th className="start-date-column">
              <span
                role="img"
                aria-label="Sort"
                style={{ cursor: "pointer" }}
                onClick={() => handleSort("startDate")}
              >
                Start Date⇅
                {/* Double arrow, replace with your preferred sorting icon */}
              </span>
            </th>

            <th>End Date</th>
            {userType === "admin" && <th></th>}
            {userType === "admin" && <th>Status</th>}
          </tr>
        </thead>
        <tbody>
          {sortedProjects.map((project) => (
            <tr key={project.id}>
              <td>{project.projectName}</td>
              {userType === "admin" && <td>{project.clientEmail}</td>}
              {userType === "admin" && (
                <td>
                  {editingAssignId === project.id ? (
                    <div className="assign-container">
                      <input
                        className="assign-edit-input"
                        value={updatedAssign}
                        onChange={handleAssignChange}
                      />
                      <div className="button-container">
                        <button
                          className="assign-edit-save-button"
                          onClick={() => handleAssignUpdate(project.id)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="assign-container">
                      <div className="assign-text">{project.assign}</div>
                      <div className="button-container">
                        <button
                          className="assign-edit-button"
                          onClick={() =>
                            handleAssignEdit(project.id, project.assign)
                          }
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  )}
                </td>
              )}

              {userType === "artist" && (
                <td>
                  {selectedFiles[project.id] &&
                  selectedFiles[project.id].length > 0 ? (
                    <div className="upload-preview-container">
                      {selectedFiles[project.id].map((file, index) => (
                        <div className="upload-preview-wrapper" key={file.name}>
                          <img
                            className="upload-preview"
                            src={URL.createObjectURL(file)}
                            alt="Upload Preview"
                          />
                          {index === 4 &&
                            selectedFiles[project.id].length > 5 && (
                              <div className="upload-preview-overlay">
                                <span>
                                  +{selectedFiles[project.id].length - 5}
                                </span>
                              </div>
                            )}
                          <button
                            className="remove-image-button"
                            onClick={() => handleRemoveImage(project.id, file)}
                          >
                            Remove
                          </button>
                        </div>
                      ))}
                      <button
                        onClick={() =>
                          onUploadImage(
                            project.id,
                            project.clientEmail,
                            project.projectName
                          )
                        }
                      >
                        Upload
                      </button>
                    </div>
                  ) : (
                    <div
                      className="upload-file-container"
                      onDragOver={handleDragOver}
                      onDrop={(event) => handleDrop(event, project.id)}
                    >
                      <input
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={(event) =>
                          handleFileChange(event, project.id)
                        }
                      />
                      <p>Drag and drop files or click to choose</p>
                    </div>
                  )}
                </td>
              )}

              <td>
                <div className="description-container">
                  {editingDescriptionId === project.id ? (
                    <textarea
                      className="description-edit-input"
                      value={updatedDescription}
                      onChange={handleDescriptionChange}
                    />
                  ) : (
                    <div className="description-text" key={project.id}>
                      <Linkify componentDecorator={customComponentDecorator}>
                        {project.description}
                      </Linkify>
                    </div>
                  )}
                  {userType === "admin" && (
                    <div className="button-container">
                      {editingDescriptionId === project.id ? (
                        
                        <button
                          className="description-edit-save-button"
                          onClick={() => handleDescriptionUpdate(project.id)}
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          className="description-edit-button"
                          onClick={() =>
                            handleDescriptionEdit(
                              project.id,
                              project.description
                            )
                          }
                        >
                          Edit
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </td>
              <td>{project.startDate}</td>
              <td
                className={
                  project.done
                    ? ""
                    : new Date(project.endDate) < new Date()
                    ? "blinking"
                    : ""
                }
              >
                {project.endDate}
              </td>
              {userType === "admin" && !project.done && (
                <td>
                  <button
                    className="project-delete-button"
                    onClick={() => handleProjectDelete(project.id)}
                  >
                    Delete
                  </button>
                </td>
              )}
              {userType === "admin" && !project.done && (
                <td>
                  {/* Existing code... */}
                  <button
                    className="project-done-button"
                    onClick={() => handleProjectDone(project.id)}
                  >
                    Done
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProjectList;
