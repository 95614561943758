import React, { useState, useEffect } from 'react';
import Login from './components/Login';
import AdminDashboard from './components/AdminDashboard';
import ArtistDashboard from './components/ArtistDashboard';

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [userType, setUserType] = useState('');

  // Define unique usernames and passwords for each artist
  const artistCredentials = {
    Vardan: 'UhVYMdIiGDVm8sw',
    Himanshu: 'FWFPpN4jmm77lpI',
    Aakash: 'a0vaYhEoLq8SYXX',
    Kapil: 'qClurPGrnexe236',
    Hameed: 'ttCaFJeJwPFI37p',
  };

  // Define admin credentials
  const adminCredentials = {
    adminUser: 'Jk3agBtQoO8x2KO',
  };

  useEffect(() => {
    // Check if login information exists in local storage
    const storedLoggedIn = localStorage.getItem('loggedIn');
    const storedUserType = localStorage.getItem('userType');

    if (storedLoggedIn && storedUserType) {
      setLoggedIn(true);
      setUserType(storedUserType);
    }
  }, []);

  const handleLogin = (username, password) => {
    if (username in artistCredentials && artistCredentials[username] === password) {
      setLoggedIn(true);
      setUserType(username); // Set the username as the userType
      localStorage.setItem('loggedIn', 'true');
      localStorage.setItem('userType', username);
    } else if (username === 'admin3dspot' && password === adminCredentials.adminUser) {
      setLoggedIn(true);
      setUserType('admin'); // Set userType as 'admin' for admin login
      localStorage.setItem('loggedIn', 'true');
      localStorage.setItem('userType', 'admin');
    } else {
      alert('Invalid username or password');
    }
  };

  const handleLogout = () => {
    setLoggedIn(false);
    setUserType('');
    localStorage.removeItem('loggedIn');
    localStorage.removeItem('userType');
  };

  return (
    <div className="app">
      {loggedIn ? (
        <>
          {userType === 'admin' ? (
            <AdminDashboard onLogout={handleLogout} />
          ) : (
            <ArtistDashboard onLogout={handleLogout} userType={userType} />
          )}
        </>
      ) : (
        <Login onLogin={handleLogin} />
      )}
    </div>
  );
};

export default App;
