import React, { useState, useEffect, useMemo } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { fetchProjectsFromS3 } from '../s3API';
import ProjectList from './ProjectList';
import '../styles/ArtistDashboard.css';
import io from 'socket.io-client';


const ArtistDashboard = ({ onLogout, userType }) => {
  const [projects, setProjects] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [uploading, setUploading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 20;
  const socket = useMemo(() => io("https://api-spotauto.onrender.com"), []);



  // Memoize the filtered projects
  const filteredProjects = useMemo(() => {
    const searchTermLowerCase = searchTerm.toLowerCase();
    return projects.filter((project) => {
      const projectNameLowerCase = project?.projectName?.toLowerCase() || '';
      const clientEmailLowerCase = project?.clientEmail?.toLowerCase() || '';
      const assignLowerCase = project?.assign?.toLowerCase() || '';

      if (
        assignLowerCase === '' ||
        assignLowerCase === userType.toLowerCase() ||
        assignLowerCase.includes('all') ||
        userType === 'admin'
      ) {
        return (
          projectNameLowerCase.includes(searchTermLowerCase) ||
          clientEmailLowerCase.includes(searchTermLowerCase) ||
          assignLowerCase.includes(searchTermLowerCase)
        );
      } else {
        return false;
      }
    });
  }, [searchTerm, userType, projects]);


  useEffect(() => {
    const handleDescriptionUpdated = ({ projectId, updatedDescription }) => {
      setProjects((currentProjects) =>
        currentProjects.map((project) => {
          if (project.id === projectId) {
            return { ...project, description: updatedDescription };
          }
          return project;
        })
      );
    };
  
    const handleAssignUpdated = ({ projectId, updatedAssign }) => {
      console.log(`Assign update received for project ID: ${projectId}: ${updatedAssign}`);
      setProjects((currentProjects) =>
        currentProjects.map((project) => {
          if (project.id === projectId) {
            return { ...project, assign: updatedAssign };
          }
          return project;
        })
      );
    };
  
    // Check if socket is connected before adding the listener
    if (socket && socket.connected) {
      socket.on("descriptionUpdated", handleDescriptionUpdated);
      socket.on("assignUpdated", handleAssignUpdated);
    }
  
    return () => {
      // Clean up the listeners when the component unmounts
      if (socket && socket.connected) {
        socket.off("descriptionUpdated", handleDescriptionUpdated);
        socket.off("assignUpdated", handleAssignUpdated);
      }
    };
  }, [socket, setProjects]); // You might need to adjust dependencies based on your state management
  

  
  // Fetch projects on initial load and when currentPage changes
  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const fetchedProjects = await fetchProjectsFromS3('artist');
        setProjects(fetchedProjects);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProjectData();
  }, [currentPage]);

  // Handle pagination
  const getPaginatedProjects = () => {
    const indexOfLastProject = currentPage * projectsPerPage;
    const indexOfFirstProject = indexOfLastProject - projectsPerPage;
    return filteredProjects.slice(indexOfFirstProject, indexOfLastProject);
  };
  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 3;

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
      const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

      if (startPage > 1) {
        pageNumbers.push(1, '...');
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      if (endPage < totalPages) {
        pageNumbers.push('...', totalPages);
      }
    }

    return pageNumbers;
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const onUploadImage = async (projectId, clientEmail, projectName) => {
    if (uploading) {
      return;
    }

    const selectedProject = projects.find((project) => project.id === projectId);

    if (selectedProject) {
      const files = selectedFiles[projectId];

      if (files && files.length > 0) {
        const formData = new FormData();
        formData.append('projectId', projectId);
        formData.append('clientEmail', clientEmail);
        formData.append('projectName', projectName);

        for (let i = 0; i < files.length; i++) {
          formData.append('images', files[i]);
        }

        setUploading(true);

        try {
          await axios.post('https://api-spotauto.onrender.com/upload', formData);
          toast.success('Images uploaded successfully!');
          setSelectedFiles((prevSelectedFiles) => ({
            ...prevSelectedFiles,
            [projectId]: [],
          }));
        } catch (error) {
          console.error('Error uploading images:', error);
        } finally {
          setUploading(false);
        }
      } else {
        toast.error('No files selected!');
      }
    }
  };

  const currentProjects = getPaginatedProjects();
  const totalPages = Math.ceil(filteredProjects.length / projectsPerPage);
  const pageNumbers = generatePageNumbers();

  return (
    <div className="artist-dashboard">
      <div className="artist-header">
        <div className="artist-dashboard-text">
          <h2>Artist Dashboard</h2>
        </div>
        <div className="logout-button-container">
          <button className="logout-button" onClick={onLogout}>
            Logout
          </button>
        </div>
      </div>

      <div className="project-list">
        <h3>Projects</h3>
        <div className="search-container">
          <input
            type="text"
            placeholder="Search project..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        <div className="pagination">
          {pageNumbers.map((pageNumber, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(pageNumber)}
              className={currentPage === pageNumber ? 'active' : ''}
              disabled={currentPage === pageNumber || pageNumber === '...'}
            >
              {pageNumber}
            </button>
          ))}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
        <ProjectList
          projects={currentProjects}
          userType="artist"
          onUploadImage={onUploadImage}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          setProjects={setProjects}
        />
      </div>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default ArtistDashboard;