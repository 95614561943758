import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { saveProjectToS3, fetchProjectsFromS3, updateProjectDescriptionInS3, updateProjectAssignInS3, deleteProjectFromS3, updateProjectDataInS3 } from '../s3API';
import ProjectForm from './ProjectForm';
import ProjectList from './ProjectList';
import { ToastContainer, toast } from 'react-toastify'; // Import the toast component and styles
import 'react-toastify/dist/ReactToastify.css'; // Import the toast styles
import '../styles/AdminDashboard.css';
import io from 'socket.io-client';
import axios from 'axios';

const socket = io('https://api-spotauto.onrender.com'); // Adjust according to your server setup


const AdminDashboard = ({ onLogout }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [projects, setProjects] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 20;


  const [updatedDescription, setUpdatedDescription] = useState('');

// At the top of your AdminDashboard component, add:
const [editingDescriptionId, setEditingDescriptionId] = useState(null);

  useEffect(() => {
    fetchProjectList();
  }, []);

  useEffect(() => {
    // Listening for a confirmation event, e.g., 'updateConfirmed'
    socket.on('updateConfirmed', (data) => {
        // Assuming 'data' contains information about the update, e.g., project ID and a success message
        toast.success(`Update confirmed for project: ${data.projectId}`);
        // Optionally, refresh project list or update state based on the confirmation
    });

    return () => {
        socket.off('updateConfirmed'); // Clean up to avoid memory leaks
    };
}, []);

  const fetchProjectList = () => {
    fetchProjectsFromS3('admin')
      .then((fetchedProjects) => {
        setProjects(fetchedProjects);
      })
      .catch((error) => {
        console.error('Error fetching projects:', error);
      });
  };

  const handleProjectSubmit = (projectName, clientEmail, description, startDate, endDate, assign) => {
    const projectData = {
      projectName: projectName,
      clientEmail: clientEmail,
      description: description,
      startDate: startDate,
      endDate: endDate,
      assign: assign

    };
    const filename = generateUniqueFilename();

    saveProjectToS3(filename, projectData)
      .then(() => {
        console.log('Project saved to S3 successfully.');
        fetchProjectList();
        toast.success('Project created successfully!', {
          autoClose: 3000 // Close the toast after 3 seconds
        });
      })
      .catch((error) => {
        console.error('Error saving project to S3:', error);
        toast.error('Error creating project.', {
          autoClose: 3000
        });
      });
  };

  const generateUniqueFilename = () => {
    return uuidv4();
  };



  const handleUpdateDescription = (projectId, newDescription) => {
    updateProjectDescriptionInS3(projectId, newDescription)
    if (!projectId || newDescription.trim() === '') {
      console.error("Project ID or updated description is missing.");
      return;
    }
   
    fetchProjectList(); 
  
    
  };
  
  

  const handleUpdateAssign = (projectId, newAssign) => {
    // Call the updateProjectAssignInS3 function from the s3API
    updateProjectAssignInS3(projectId, newAssign)
      .then(() => {
        console.log('Project Assign To updated successfully.');
        fetchProjectList(); // Fetch the updated project list
      })
      .catch((error) => {
        console.error('Error updating project assign:', error);
      });
  };

  const handleProjectDeletion = (projectId) => {
    deleteProjectFromS3(projectId)
      .then(() => {
        console.log(`Project with ID ${projectId} has been deleted.`);
        fetchProjectList();
      })
      .catch((error) => {
        console.error('Error deleting project:', error);
      });
  };
  const handleProjectDone = (projectId) => {
    const updatedData = {
      done: true,
    };

    updateProjectDataInS3(projectId, updatedData)
      .then(() => {
        console.log(`Project with ID ${projectId} marked as done.`);
        fetchProjectList();
      })
      .catch((error) => {
        console.error('Error updating project:', error);
      });
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Function to generate an array of page numbers to display in pagination
  const generatePageNumbers = (totalPages, currentPage) => {
    const pageNumbers = [];
    const maxPagesToShow = 5;

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
      const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

      if (startPage > 1) {
        pageNumbers.push(1, '...');
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      if (endPage < totalPages) {
        pageNumbers.push('...', totalPages);
      }
    }

    return pageNumbers;
  };


  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset page to 1 when the search term changes
  };

  const filteredProjects = projects.filter((project) => {
    const searchTermLowerCase = searchTerm.toLowerCase();
    const projectNameLowerCase = project?.projectName?.toLowerCase() || '';
    const clientEmailLowerCase = project?.clientEmail?.toLowerCase() || '';
    const assignLowerCase = project?.assign?.toLowerCase() || '';

    return (
      projectNameLowerCase.includes(searchTermLowerCase) ||
      clientEmailLowerCase.includes(searchTermLowerCase) ||
      assignLowerCase.includes(searchTermLowerCase)
    );
  });


  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = filteredProjects.slice(
    indexOfFirstProject,
    indexOfLastProject
  );

  const totalPages = Math.ceil(filteredProjects.length / projectsPerPage);
  const pageNumbers = generatePageNumbers(totalPages, currentPage);
  return (
    <div className="admin-dashboard">
      <div className="admin-header">
        <h2>Admin Dashboard</h2>
        <button className="logout-button" onClick={onLogout}>
          Logout
        </button>
      </div>


      <ProjectForm onSubmit={handleProjectSubmit} />


      <h3>Client List</h3>


      <div className="search-container">
        <input
          type="text"
          placeholder="Search project..."
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>
      <div className="pagination">
        {pageNumbers.map((pageNumber, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(pageNumber)}
            className={currentPage === pageNumber ? 'active' : ''}
            disabled={currentPage === pageNumber}
          >
            {pageNumber}
          </button>
        ))}
        <span className="ellipsis"></span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          className={currentPage === totalPages ? 'active' : ''}
          disabled={currentPage === totalPages}
        >
          Next
        </button>

      </div>
      <ProjectList
        projects={currentProjects}
        userType="admin"
        onUpdateDescription={handleUpdateDescription}
        onUpdateAssign={handleUpdateAssign}
        onProjectDeletion={handleProjectDeletion}
        onProjectDone={handleProjectDone}
        
        setProjects={setProjects} // Make sure the prop name is correct

      />

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />



    </div>
  );
};

export default AdminDashboard;
