import React, { useState } from 'react';
import '../styles/ProjectForm.css';

const ProjectForm = ({ onSubmit }) => {
  const [projectName, setProjectName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [assign, setassign] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if all inputs are filled
    if (projectName && clientEmail && description && startDate && endDate && assign) {
      onSubmit(projectName, clientEmail, description, startDate, endDate, assign);
      setProjectName('');
      setClientEmail('');
      setDescription('');
      setStartDate('');
      setEndDate('');
      setassign('');
    } else {
      alert('Please fill in all fields before creating a project.');
    }
  };
  // Function to calculate the end date as the 12th working day after the starting date
  const calculateEndDate = (start) => {
    const workingDaysToAdd = 15; // Number of working days to add
    let currentDate = new Date(start);

    // Loop through the days and skip weekends
    for (let i = 0; i < workingDaysToAdd;) {
      currentDate.setDate(currentDate.getDate() + 1);
      // Skip weekends (Saturday: 6, Sunday: 0)
      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
        i++;
      }
    }

    return currentDate.toISOString().split('T')[0]; // Return the calculated date in 'YYYY-MM-DD' format
  };

  const handleStartDateChange = (value) => {
    setStartDate(value);
    // Automatically calculate and set the end date when the starting date changes
    const calculatedEndDate = calculateEndDate(value);
    setEndDate(calculatedEndDate);
  };

  return (
    <div className="project-form">
      <h3>Create Project</h3>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Project Name"
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
        />
        <input
          type="email"
          placeholder="Client Email"
          value={clientEmail}
          onChange={(e) => setClientEmail(e.target.value)}
        />

        <label>
          <select
            value={assign}
            onChange={(e) => setassign(e.target.value)}
          >
            <option value="None">None</option>
            <option value="Aakash">Aakash</option>
            <option value="Hameed">Hameed</option>
            <option value="Himanshu">Himanshu</option>
            <option value="Kapil">Kapil</option>
            <option value="Vardan">Vardan</option>
            <option value="Vardan">all</option>
          </select>
        </label>
        <textarea
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        ></textarea>

        <p>Starting Date:
          <input
            type="date"
            placeholder="Starting Date"
            value={startDate}
            onChange={(e) => handleStartDateChange(e.target.value)}
          />
        </p>
        <p>Ending Date:
          <input
            type="date"
            placeholder="Ending Date"
            value={endDate}
            readOnly // Make the end date field read-only
          />
        </p>


        <button type="submit">Create</button>
      </form>
    </div>
  );
};

export default ProjectForm;
