import AWS from 'aws-sdk';

const bucketName = 'spotautojson';
// Frontend code
const fetchS3Config = async () => {
  try {
    const response = await fetch('https://api-spotauto.onrender.com/s3config');
    const s3Config = await response.json();
   
    return s3Config;
  } catch (error) {
    console.error('Error fetching S3 configuration:', error);
    throw error;
  }
};

// Usage
const s3Config = await fetchS3Config();

// Now you can use s3Config.accessKeyId, s3Config.secretAccessKey, and s3Config.region in your S3 client configuration.
const s3 = new AWS.S3(s3Config);


export const saveProjectToS3 = async (filename, projectData) => {
  const params = {
    Bucket: bucketName,
    Key: `${filename}.json`,
    Body: JSON.stringify(projectData),
    ContentType: 'application/json',
  };

  try {
    await s3.putObject(params).promise();
    console.log('Project saved successfully.');
  } catch (error) {
    console.error('Error saving project:', error);
    throw error;
  }
};

async function fetchWithConcurrencyLimit(tasks, limit) {
  const results = [];
  const executing = [];

  for (const task of tasks) {
    const promise = Promise.resolve().then(() => task());
    results.push(promise);

    if (limit <= tasks.length) {
      const e = promise.then(() => executing.splice(executing.indexOf(e), 1));
      executing.push(e);
      if (executing.length >= limit) {
        await Promise.race(executing);
      }
    }
  }

  return Promise.all(results);
}

export const fetchProjectsFromS3 = async (userType) => {
  const params = { Bucket: bucketName };
  let projects = [];
  let isTruncated = true;

  try {
    while (isTruncated) {
      const data = await s3.listObjectsV2(params).promise();
      isTruncated = data.IsTruncated;
      if (data.NextContinuationToken) {
        params.ContinuationToken = data.NextContinuationToken;
      }

      if (!data.Contents) {
        break;
      }

      const jsonFiles = data.Contents.filter((object) => object.Key.endsWith('.json'));

      // Create a list of tasks for fetching project data
      const fetchTasks = jsonFiles.map((object) => async () => {
        const projectId = object.Key.split('.')[0];
        try {
          const projectData = await s3.getObject({ Bucket: bucketName, Key: object.Key }).promise();
          const parsedData = JSON.parse(projectData.Body.toString(''));
          const { projectName, clientEmail, description = '', startDate = '', endDate = '', assign = '', done = false } = parsedData;

          return {
            id: projectId,
            projectName,
            clientEmail,
            description,
            startDate,
            endDate,
            assign,
            done,
          };
        } catch (error) {
          console.error(`Error parsing project data for project ID ${projectId}:`, error);
          return null;
        }
      });

      // Fetch project data with concurrency control
      const projectsBatch = await fetchWithConcurrencyLimit(fetchTasks, 5); // Adjust the concurrency limit as needed
      projects = projects.concat(projectsBatch.filter((project) => project !== null && (userType === 'admin' || !project.done)));
    }

    console.log(`Fetched ${projects.length} projects from S3.`);
    return projects;
  } catch (error) {
    console.error('Error fetching projects:', error);
    return [];
  }
};
export const updateProjectDescriptionInS3 = async (projectId, newDescription) => {
  try {
    const params = {
      Bucket: bucketName,
      Key: `${projectId}.json`,
    };

    const data = await s3.getObject(params).promise();
    const projectData = JSON.parse(data.Body.toString(''));
    projectData.description = newDescription;

    const updatedParams = {
      Bucket: bucketName,
      Key: `${projectId}.json`,
      Body: JSON.stringify(projectData),
      CacheControl: 'no-cache, no-store, must-revalidate',
      ContentType: 'application/json',
    };

    await s3.putObject(updatedParams).promise();
    console.log('Project description updated successfully.');
  } catch (error) {
    console.error('Error updating project description:', error);
    throw error;
  }
};

export const deleteProjectFromS3 = async (projectId) => {
  try {
    const params = {
      Bucket: bucketName,
      Key: `${projectId}.json`,
    };

    await s3.deleteObject(params).promise();
    console.log('Project deleted successfully.');
  } catch (error) {
    console.error('Error deleting project:', error);
    throw error;
  }
};

export const updateProjectAssignInS3 = async (projectId, newAssign) => {
  try {
    const params = {
      Bucket: bucketName,
      Key: `${projectId}.json`,
    };

    const data = await s3.getObject(params).promise();
    const projectData = JSON.parse(data.Body.toString(''));
    projectData.assign = newAssign;

    const updatedParams = {
      Bucket: bucketName,
      Key: `${projectId}.json`,
      Body: JSON.stringify(projectData),
      CacheControl: 'no-cache, no-store, must-revalidate',
      ContentType: 'application/json',
    };

    await s3.putObject(updatedParams).promise();
    console.log('Project assignment updated successfully.');
  } catch (error) {
    console.error('Error updating project assignment:', error);
    throw error;
  }
};

export const updateProjectDataInS3 = async (projectId, updatedData) => {
  try {
    const params = {
      Bucket: bucketName,
      Key: `${projectId}.json`,
    };

    const data = await s3.getObject(params).promise();
    const projectData = JSON.parse(data.Body.toString());
    const updatedProjectData = {
      ...projectData,
      ...updatedData,
    };

    const updatedParams = {
      Bucket: bucketName,
      Key: `${projectId}.json`,
      Body: JSON.stringify(updatedProjectData),
    };

    await s3.putObject(updatedParams).promise();
    console.log('Project data updated successfully.');
  } catch (error) {
    console.error('Error updating project data:', error);
    throw error;
  }
};

